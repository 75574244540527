import React, {Component} from 'react';
import injectSheet from "react-jss";
import playButton from '../assets/svg/play-button.svg'
import playButton_dark from '../assets/svg/dark/play-button_dark.svg'
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";
import {Col, Row} from "react-flexbox-grid";
import {isMobile, isTablet} from 'react-device-detect';
import {hoverCardOnMobile} from "../containers/utils/functionutils";

const styles = {
    root: {
        position: 'relative',
        overflow: 'hidden',
        height: '451px',
        width: '300px',
        marginBottom: '2vh',
        "@media (max-width:325px)": { //For Iphone 5/SE or belove
            height: '398px',
            width: '265px',
        },
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        transition: 'transform .2s ease-in-out, filter .2s ease-in-out',
        '&:hover': hoverCardOnMobile(),
    },
    playButton: {
        position: 'absolute',
        margin: '0px',
        top: 'calc(39% - 40px)',
        right: 'calc(50% - 40px)',
        cursor: 'pointer',
        opacity: '.75',
        transition: 'opacity .2s ease-in-out',
        '&:hover': {
            opacity: '1'
        }
    },
    textRoot: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        height: '31.8%',
        background: 'rgba(0,0,0, .85)',
        zIndex: '10'
    },
    mainTitle: {
        margin: '0px',
        padding: '0px',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'bold',
        fontSize: () => !isMobile ? 'calc(18pt + (25 - 18) * ((100vw - 1366px) / (1920 - 1366)))' : isTablet ? '135%' : '18pt',
        color: '#ffde1d',
        "@media (max-width:325px)": {
            fontSize: '14pt !important',
        },
    },
    year: {
        margin: '0px',
        padding: '0px',
        paddingLeft: '5px',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'bold',
        fontSize: () => !isMobile ? 'calc(10pt + (14 - 10) * ((100vw - 1366px) / (1920 - 1366)))' : '10pt',
        color: '#ffde1d'
    },
    descTitle: {
        margin: '0px',
        padding: '0px',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'bold',
        fontSize: () => !isMobile ? 'calc(9pt + (12.6 - 9) * ((100vw - 1366px) / (1920 - 1366)))' : '9pt',
        color: '#ffde1d',
        "@media (max-width:325px)": {
            fontSize: '8.5pt !important',
        },
    },
    descText: {
        margin: '0px',
        padding: '0px',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'normal',
        fontSize: () => !isMobile ? 'calc(9pt + (12.6 - 9) * ((100vw - 1366px) / (1920 - 1366)))' : '9pt',
        color: '#fff',
        "@media (max-width:325px)": {
            fontSize: '8.5pt !important',
        },
    },
    modalBg: {
        background: 'rgba(255, 255, 255, .24)',
        borderRadius: '10px'
    },
    closeButtomContainer: {
        position: 'absolute',
        right: '-20px',
        top: '-40px',
        color: 'rgba(255, 255, 255, .75)',
        fontWeight: '700',
        fontSize: '89%',
        border: '2px solid rgba(255, 255, 255, .75)',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        background: 'rgba(0, 0, 0, .3)',
        '&:hover': {
            color: '#fff',
            borderColor: '#fff'
        }
    }

};

class MovieCard extends Component {

    state = {openPlayModal: false};

    handlePlayModal = () => () => {
        const {isDirectVideoUrl = false} = this.props;
        if (!isDirectVideoUrl) {
            this.setState(prevState => ({openPlayModal: !prevState.openPlayModal}))
        } else {
            window.open(this.props.videoUrl, '_blank');
        }
    };

    handleCloseModal = () => () => {
        this.setState({openPlayModal: false})
    };

    youtTubeVideo = (videoUrl, isVideoProfile) => {

        const InnerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const width = InnerWidth < 640 ? `${Math.round(InnerWidth * 0.75)}px` : `640px`;


        if (!isVideoProfile) {
            return <ReactPlayer url={videoUrl} width={width}/>
        }
    };

    capitalizeAndSplitCamelCase = (s) => {
        if (typeof s !== 'string') return '';
        return s.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
            return str.toUpperCase();
        })
    };

    descriptoonTextGenerator = (descTexts) => {
        return descTexts.map((textObj, i) => {
            const {classes} = this.props;
            const [title, description] = Object.entries(textObj)[0];
            const normFormTitle = this.capitalizeAndSplitCamelCase(title);

            return (
                <Row start={"xs"} key={`DescText-${i}`} style={{color: '#fff'}}>
                    <p className={classes.descTitle}>{`${normFormTitle}: `}<span
                        className={classes.descText}>{description}</span></p>
                </Row>
            )
        })
    };

    render() {
        const {classes, picture, title, year, descriptionTexts, play, videoUrl, isDirectVideoUrl = false, dark = false} = this.props;
        const {openPlayModal} = this.state;
        const rootStyle = !isMobile ? {width: '80%', height: '70vh'} : {};

        return (
            <div id={'MovieCardRoot'} className={classes.root} style={rootStyle}>
                <img className={classes.img} src={picture} alt={'MovieCardPic'}/>
                {play &&
                <img onClick={this.handlePlayModal()} height={80} className={classes.playButton}
                     src={dark ? playButton_dark : playButton} alt={'playButton'}/>}
                <div className={classes.textRoot}>
                    <Row start={"xs"}>
                        <Col xs={1}/>
                        <Col xs={10}>
                            <Row>
                                <Col xs={12} style={{padding: '0px', paddingTop: '10px', paddingBottom: '10px'}}>
                                    <p className={classes.mainTitle}>{title}
                                        {year && <span
                                            className={classes.year}>{`(${year})`}</span>}
                                    </p>
                                </Col>
                            </Row>
                            {this.descriptoonTextGenerator(descriptionTexts)}
                        </Col>
                        <Col xs={1}/>
                    </Row>
                </div>
                <Modal
                    open={openPlayModal}
                    onClose={this.handlePlayModal()}
                    blockScroll
                    center
                    closeOnOverlayClick
                    showCloseIcon={false}
                    classNames={{
                        modal: classes.modalBg,
                    }}
                >
                    <div className={classes.closeButtomContainer} style={{position: 'absolute'}}
                         onClick={this.handleCloseModal()}>
                        <p>✖</p>
                    </div>
                    {this.youtTubeVideo(videoUrl, isDirectVideoUrl)}
                </Modal>
            </div> //openPlayModal
        )
    }
}

export default injectSheet(styles)(MovieCard)
