import React, {PureComponent} from 'react';
import './css/loadingcomponent.css'

class LoadingComponent extends PureComponent {

    render() {
        return (
            <div className="lds-ripple">
                <div/>
                <div/>
            </div>
        )
    }
}

export default (LoadingComponent)