import React, {Component} from 'react'
import {slide as Menu} from 'react-burger-menu'
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import {Link} from 'react-scroll';
import classNames from "classnames";
import {Animated} from "react-animated-css";

const styles = {
    root: props => ({
        zIndex: '99',
        background: 'rgba(0,0,0, .95)',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'normal',
        overflow: 'hidden',
        fontSize: props.isEng ? '1.7rem' : '1.5rem'
    }),
    navLinks: {
        paddingLeft: '20px',
        color: '#fff',
        opacity: '.7',
        margin: '0px',
        textDecoration: 'none',
    },
    activeLink: {
        opacity: '1',
        color: '#ffde1d',
    },
    hrStyle: {
        color: '#fff',
        opacity: '.1',
        backgroundColor: '#fff',
        height: '1px',
        minWidth: '277px'
    },
    linkColStyle: {
        paddingRight: '0px',
    },
    linkRowStyle: {
        maxWidth: '280px',
        paddingTop: '5vh'
    },
    noFocus: {
        '&:focus': {
            outline: 'none !important'
        }
    },
    changeLang: {
        paddingLeft: '20px',
        color: '#fff',
        opacity: '.7',
        margin: '0px',
        textDecoration: 'none',
        fontSize: '1rem'
    }
};

class BurgerMenu extends Component {

    isMenuOpen = (state) => {
        if (!state.isOpen) {
            this.props.handleOpen(state);
        }
    };

    navlinks = (navs, activeLink) => {
        const {classes, isOpen, handleOpen} = this.props;
        return (
            navs.map((nav, index) => (
                <Animated key={`navLinkBurger-${index}`} animationIn="slideInLeft" animationInDelay={(index + 1) * 25}
                          isVisible={isOpen}>
                    <Col xs={12} className={classNames(classes.linkColStyle, classes.noFocus)}>
                        <Link activeClass="active"
                              to={nav.src}
                              onClick={() => handleOpen({isOpen: false})}
                              spy={true}
                              isDynamic={true}
                              smooth={true}
                        >
                            <p className={classNames(classes.navLinks, activeLink === index ? classes.activeLink : '')}>
                                {nav.title}
                            </p>
                        </Link>
                        <hr className={classes.hrStyle}/>
                    </Col>
                </Animated>
            ))
        )
    };

    render() {
        const {classes, isOpen, navs, activeLink, flag} = this.props;
        return (
            <Menu
                className={classes.root}
                isOpen={isOpen}
                disableCloseOnEsc
                disableAutoFocus
                width={'280px'}
                onStateChange={this.isMenuOpen}>
                <Row className={classNames(classes.linkRowStyle, classes.noFocus)}>
                    {this.navlinks(navs, activeLink)}
                </Row>
                <Row center={'xs'} style={{maxWidth: '280px', padding: '3vh'}} className={classes.noFocus}>
                    <Animated animationIn="fadeInUp"
                              animationInDelay={navs.length * 50}
                              isVisible={isOpen}>
                        <Col xs={12} className={classes.noFocus}>
                            {flag(40)}
                        </Col>
                    </Animated>
                </Row>
            </Menu>
        );
    }
}

export default injectSheet(styles)(BurgerMenu)