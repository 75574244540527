import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import background from "../assets/images/bg/clipped-stone-bg-director_v1.png";
import {OUR_DIRECTORS} from "../constants/content";
import Card from "../compontents/card";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import {
    bgType,
    decideDeviceBackground,
    decideDeviceBottomShadow,
    decideDeviceTopShadow,
    parallaxScrollOnMobile
} from "./utils/functionutils";

const styles = {
    root: {
        backgroundImage: () => decideDeviceBackground(bgType.DARK, background),
        boxShadow: ()=> decideDeviceTopShadow('inset 1px 14px 28px -6px rgba(0, 0, 0, 0.5)'),
        backgroundSize: 'cover',
        minHeight: '100vh',
        "@media (min-width:800px)": parallaxScrollOnMobile()
    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    headline: {
        // color: '#ff381a',
        color: '#ffde1d',
        //textShadow: '6px 8px 14px rgba(0, 0, 0, .6)',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize:`calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        //fontWeight: '900',
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
        textShadow: '2px 2px 0px #000000'
    },
    contentContainer: {
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },
        marginTop: '30px'
    },
    bottomShadow: {
        position: 'absolute',
        pointerEvents: 'none',
        minWidth: '100vw',
        minHeight: '100vh',
        bottom: '0px',
        boxShadow: 'inset 1px -14px 28px -6px rgba(0, 0, 0, 0.5)',
    }
};

class OurDirectors extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#ourdirectors')
        }
    }

    personCard = () => {
        const {isVisibleFromTop} = this.props;
        return (
            <Fragment>
                {OUR_DIRECTORS.persons.map((person, index) => {
                    return (
                        <Col xs={12} md={6} key={`person-${index}`}>
                            <Animated animationIn="flipInX" animationInDelay={(index + 8) * 100}
                                      isVisible={isVisibleFromTop}>
                                <Card
                                    picture={person.picture}
                                    name={person.name}
                                    title={person.title.eng}
                                    imdb={person.imdb}
                                    imdbSrc={person.imdbSrc}
                                    web={person.web}
                                    webSrc={person.webSrc}
                                    play={person.play}
                                    isVideoProfile={person.videoProfile}
                                    videoUrl={person.videoUrl}
                                    isDarkTheme={person.darkTheme}
                                />
                            </Animated>
                        </Col>

                    )
                })}
            </Fragment>
        )
    };

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        return (
            <div id={'ourDirectors'} className={classes.root}>
                <div className={decideDeviceBottomShadow(classes.bottomShadow)}/>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>{OUR_DIRECTORS.title[pageLanguage]}</p>
                        </Col>
                    </Row>
                </Animated>
                <Row className={classes.contentContainer}>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    <Col xs={10} lg={7} className={classes.contentContainer}>
                        <Row center={'lg'}>
                            {this.personCard()}
                        </Row>
                    </Col>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                </Row>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(OurDirectors)
