import React, {Component} from 'react';
import injectSheet from 'react-jss'
import {Col, Row} from "react-flexbox-grid";
import Breakpoint from 'react-socks';
import landingbg from "../assets/images/bg/landingbg_comp.jpg";
import landingbgmobile from "../assets/images/bg/mobile/mobile_landingbg_v_wf_comp_v2.jpg";
import landingbgtablet from "../assets/images/bg/mobile/tablet_landingbg_v_wf_comp.jpg";
import {withRouter} from "react-router";
import {compose} from "redux";
import {isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import {decideDeviceBottomShadow, parallaxScrollOnMobile} from "./utils/functionutils";

const styles = {
    root: {
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: () => isMobileOnly ? `url(${landingbgmobile})` : isTablet ? `url(${landingbgtablet})` : `url(${landingbg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: '50% 50%',
        backgroundAttachment: 'fixed',
        "@media (max-width:800px)": { ...parallaxScrollOnMobile(),  backgroundAttachment: isMobile ? 'scroll !important' : '' // for Ios
        },
    },
    headlineContainer: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        "@media (min-width:800px)": {
            paddingRight: ()=> !isMobile ? '15vw' : '',
        },
    },
    headline: {
        color: '#ffde1d',
        // textShadow: '-15px 2px 20px rgba(0, 0, 0, .4)',
        fontFamily: 'HVD Poster',
        fontSize: 'calc(2.7rem + (55 - 2.7) * ((100vw - 300px) / (1920 - 300)))',
        fontWeight: '500',
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: 'uppercase',
    },
    headlineSub: {
        color: '#ffde1d',
        // textShadow: '-15px 2px 20px rgba(0, 0, 0, .4)',
        fontFamily: 'HVD Poster',
        fontSize: 'calc(2rem + (40 - 2) * ((100vw - 300px) / (1920 - 300)))',
        fontWeight: '500',
        margin: '-1.2vh',
        marginLeft: '1vw',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: 'uppercase',
    },
    andSign: {
        color: '#ffde1d',
        // textShadow: '-15px 2px 20px rgba(0, 0, 0, .4)',
        fontFamily: 'HVD Poster',
        fontSize: 'calc(10rem + (180 - 10) * ((100vw - 300px) / (1920 - 300)))',
        fontWeight: '500',
        margin: '0px',
        marginLeft: '0px',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: 'uppercase',
        marginBottom: '-6vh'
    },
    figureContainer: {},
    figure: {
        position: 'absolute',
        height: '110vh',
        transition: 'filter 50ms ease-in-out, opacity 100ms ease-in-out, transform 250ms ease-out',
    },
    bottomShadow: {
        position: 'absolute',
        pointerEvents: 'none',
        minWidth: '100vw',
        minHeight: '100vh',
        bottom: '0px',
        boxShadow: 'inset 1px -14px 28px -6px rgba(0, 0, 0, 0.5)',
    },
    fadeIn: {
        visibility: 'visible !important',
        animation: 'FadeIn-animation 1s ease-in-out forwards normal',
    },
    fadeOut: {
        animation: 'FadeOut-animation 1.2s ease-in-out forwards normal',
    },

    [`@keyframes FadeIn-animation`]: {
        '0%': {opacity: '0', transform: 'scale(0.5) translateX(-30vw)'},
        '100%': {opacity: '1', transform: 'scale(1)'},
    },
    [`@keyframes FadeOut-animation`]: {
        '0%': {opacity: '1', transform: 'scale(1)'},
        '100%': {opacity: '0', transform: 'scale(10) translateX(30vw)', display: 'none'},
    },
};

class LandingPage extends Component {

    state = {
        activeHeadline: 0,
        blurer: 0
    };

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/')
        }
    }


    onScroll = (e) => {
        const innerHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        if (window.scrollY < innerHeight) {
            this.setState({
                blurer: Math.round(window.scrollY / 8)
            })
        }
    };

    render() {
        const {classes} = this.props;
        const {blurer} = this.state;
        return (
            <div id={'landingPage'} className={classes.root}>
                <Breakpoint large up>
                    <div className={decideDeviceBottomShadow(classes.bottomShadow)}/>
                </Breakpoint>
                <Row center={'xs'} end={'lg'}>
                    <Col xs={12} lg={12} className={classes.headlineContainer} style={{
                        filter: `blur(${blurer / 5}px)`,
                        // transform: `scale(1.${blurer/5})`
                    }}>
                        <div style={{marginRight: '1%'}}>
                            <Row center={'xs'} end={'lg'} middle={'xs'} style={{marginRight: '6vw'}}>
                                <Col>
                                    <p className={classes.headline}>Film</p>
                                    <p className={classes.headlineSub}>Production</p>
                                </Col>
                            </Row>
                            <Row center={'xs'} end={'lg'} bottom={'xs'}>
                                <Col>
                                    <p className={classes.andSign}>&</p>
                                </Col>
                                <Col>
                                    <p className={classes.headlineSub}>Location</p>
                                    <p className={classes.headlineSub}>Scouting</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(LandingPage)