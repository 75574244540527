import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import background from "../assets/images/bg/inf_stone_bg_ourworks.png";
import {OUR_WORKS} from "../constants/content";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import MovieCard from "../compontents/moviecard";
import {isMobile} from 'react-device-detect';
import {
    bgType,
    decideDeviceBackground,
    decideDeviceBottomShadow,
    decideDeviceTopShadow,
    parallaxScrollOnMobile
} from "./utils/functionutils";

const styles = {
    root: () => {
        const rootStyleObj = {
            backgroundImage:  decideDeviceBackground(bgType.DARK, background),
            boxShadow: decideDeviceTopShadow('inset 1px 14px 28px -6px rgba(0, 0, 0, 0.5)'),
            minHeight: '100vh',
            "@media (min-width:1360px)": parallaxScrollOnMobile()
        };
        if(isMobile){
            return {...rootStyleObj, backgroundRepeat: 'repeat-y', overflowX: 'hidden'}
        } else {
            return {...rootStyleObj, backgroundSize: 'cover', backgroundAttachment: 'fixed'}
        }

    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    headline: {
        // color: '#ff381a',
        color: '#ffde1d',
        //textShadow: '6px 8px 14px rgba(0, 0, 0, .6)',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        //fontWeight: '900',
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
        textShadow: '2px 2px 0px #000000'
    },
    contentContainer: {
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },
        marginTop: '15px'
    },
    bottomShadow: {
        position: 'absolute',
        pointerEvents: 'none',
        minWidth: '100vw',
        minHeight: '100vh',
        bottom: '0px',
        boxShadow: 'inset 1px -14px 28px -6px rgba(0, 0, 0, 0.5)',
    }
};

class OurWorks extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#ourworks')
        }
    }
    /*For continues animation delay on MovieCard : (index + 4) * 100*/
    movieCardRender = () => {
        const {isVisibleFromTop} = this.props;
        return (
            <Fragment>
                {OUR_WORKS.works.map((works, index) => {
                    return (
                        <Col xs={12} md={6} lg={4} key={`worksmoviecard-${index}`} style={{paddingBottom: '20px'}}>
                            <Animated animationIn="zoomIn" animationInDelay={300}
                                      isVisible={isVisibleFromTop}>
                                <MovieCard
                                    picture={works.picture}
                                    title={works.title}
                                    year={works.year}
                                    descriptionTexts={works.descriptionTexts}
                                    play={works.play}
                                    dark={works.dark}
                                    isDirectVideoUrl={works.isDirectVideoUrl}
                                    videoUrl={works.videoUrl}
                                />
                            </Animated>
                        </Col>

                    )
                })}
            </Fragment>
        )
    };

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;

        return (
            <div id={'ourWorks'} className={classes.root}>
                <div className={decideDeviceBottomShadow(classes.bottomShadow)}/>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>{OUR_WORKS.title[pageLanguage]}</p>
                        </Col>
                    </Row>
                </Animated>
                <Row center={'xs'} className={classes.contentContainer}>
                    <Col xs={1} md={0} lg={0} className={'hideOnLarge'}/>
                    <Col xs={10} md={11} lg={12} className={classes.contentContainer}>
                        <Row center={'lg'}>
                            {this.movieCardRender()}
                        </Row>
                    </Col>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                </Row>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(OurWorks)
