import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import Modal from 'react-responsive-modal';
import playButtonLight from '../assets/svg/play-button.svg'
import imdbButtonLight from '../assets/svg/imdb.svg'
import wwwwButtonLight from '../assets/svg/www.svg'
import playButtonDark from '../assets/svg/dark/play-button_dark.svg'
import imdbButtonDark from '../assets/svg/dark/imdb_dark.svg'
import wwwwButtonDark from '../assets/svg/dark/www_dark.svg'
import ReactPlayer from "react-player";
import {hoverCardOnMobile} from "../containers/utils/functionutils";

const styles = {
    root: {
        position: 'relative',
        overflow: 'hidden',
        height: '30vh',
        width: '100%',
        marginBottom: '2vh',
        borderRadius: '10px',
        /*boxShadow: '6px 10px 33px 0px rgba(0,0,0,0.45)',
        '&:hover': {
            boxShadow: 'none'
        },*/
        transition: 'box-shadow .3s ease-in-out',
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '10px',
        transition: 'transform .2s ease-in-out, filter .2s ease-in-out',
        '&:hover': hoverCardOnMobile(),
    },
    personName: {
        zIndex: '1',
        position: 'absolute',
        fontWeight: '900',
        fontFamily: 'PFAgoraSlabProReg',
        margin: '0px',
        bottom: '15%',
        left: '5%',
        fontSize: 'calc(1.5rem + (20 - 1.5) * ((100vw - 300px) / (1920 - 300)))',
        color: '#fff',
        textShadow: '1px 5px 10px rgb(0, 0, 0)',
        cursor: 'default'
    },
    personTitle: {
        zIndex: '1',
        position: 'absolute',
        fontWeight: '400',
        fontFamily: 'PFAgoraSlabProReg',
        margin: '0px',
        bottom: '5%',
        left: '7%',
        fontSize: 'calc(1rem + (10 - 1) * ((100vw - 300px) / (1920 - 300)))',
        color: '#fff',
        textShadow: '1px 5px 10px rgb(0, 0, 0)',
        cursor: 'default'
    },
    playButton: {
        position: 'absolute',
        margin: '0px',
        top: '5%',
        right: '5%',
        cursor: 'pointer',
        opacity: '.6',
        transition: 'opacity .2s ease-in-out',
        '&:hover': {
            opacity: '1'
        }
    },
    imdbButton: {
        position: 'absolute',
        margin: '0px',
        top: '5%',
        left: '5%',
        cursor: 'pointer',
        opacity: '.6',
        transition: 'opacity .2s ease-in-out',
        '&:hover': {
            opacity: '1'
        }
    },
    modalBg: {
        background: 'rgba(255, 255, 255, .24)',
        borderRadius: '10px'
    },
    closeButtomContainer: {
        position: 'absolute',
        right: '-20px',
        top: '-40px',
        color: 'rgba(255, 255, 255, .75)',
        fontWeight: '700',
        fontSize: '89%',
        border: '2px solid rgba(255, 255, 255, .75)',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        background: 'rgba(0, 0, 0, .3)',
        '&:hover': {
            color: '#fff',
            borderColor: '#fff'
        }
    }
};


class Card extends Component {

    state = {openPlayModal: false};

    openImdb = (src) => () => {
        if (src) {
            window.open(src, '_blank');
        }
    };
    openWeb = (src) => () => {
        if (src) {
            window.open(src, '_blank');
        }
    };

    handlePlayModal = () => () => {
        const {isVideoProfile = false} = this.props;
        if (!isVideoProfile) {
            this.setState(prevState => ({openPlayModal: !prevState.openPlayModal}))
        } else {
            window.open(this.props.videoUrl, '_blank');
        }
    };

    youtTubeVideo = (videoUrl, isVideoProfile) => {

        const InnerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const width = InnerWidth < 640 ? `${Math.round(InnerWidth * 0.75)}px` : `640px`;


        if (!isVideoProfile) {
            return <ReactPlayer url={videoUrl} width={width}/>
        }

    };

    handleCloseModal = () => () => {
        this.setState({openPlayModal: false})
    };

    render() {
        const {classes, imdb = false, play = false, web = false, isDarkTheme = false, name = '', title = '', imdbSrc = '', videoUrl = '', webSrc='', isVideoProfile = false, picture} = this.props;
        const {openPlayModal} = this.state;
        const playButton = isDarkTheme ? playButtonDark : playButtonLight;
        const imdbButton = isDarkTheme ? imdbButtonDark : imdbButtonLight;
        const wwwwButton = isDarkTheme ? wwwwButtonDark : wwwwButtonLight;

        return (
            <Fragment>
                <div className={classes.root}>
                    <img className={classes.img} src={picture} alt={'CardPic'}/>
                    {play &&
                    <img onClick={this.handlePlayModal()} height={50} className={classes.playButton}
                         src={playButton} alt={'playButton'}/>}
                    {imdb && <img onClick={this.openImdb(imdbSrc)} height={50}
                                  className={play ? classes.imdbButton : classes.playButton} src={imdbButton}
                                  alt={'imdbButton'}/>}
                    {web && <img onClick={this.openWeb(webSrc)} height={50}
                                  className={play ? classes.imdbButton : classes.playButton} src={wwwwButton}
                                  alt={'imdbButton'}/>}
                    <p className={classes.personName}>{name}</p>
                    <p className={classes.personTitle}>{title}</p>
                </div>
                <Modal
                    open={openPlayModal}
                    onClose={this.handlePlayModal()}
                    blockScroll
                    center
                    closeOnOverlayClick
                    showCloseIcon={false}
                    classNames={{
                        modal: classes.modalBg,
                    }}
                >
                    <div className={classes.closeButtomContainer} style={{position: 'absolute'}} onClick={this.handleCloseModal()}>
                        <p>✖</p>
                    </div>
                    {this.youtTubeVideo(videoUrl, isVideoProfile)}
                </Modal>
            </Fragment>
        )
    }
}

export default injectSheet(styles)(Card);
