import React, {Component, Fragment} from 'react';
import injectSheet from 'react-jss'
import {Col, Row,} from "react-flexbox-grid";
import {CONTACT_US} from "../constants";
import background from '../assets/images/bg/clipped-stone-bg-contact-light.png'
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import {bgType, decideDeviceBackground} from "./utils/functionutils";


const styles = {
    root: {
        backgroundImage: () => decideDeviceBackground(bgType.LIGHT, background),
        //boxShadow: ''inset 1px 14px 28px -6px rgba(0, 0, 0, 0.5)'',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',

    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    contentContainer: {
        marginTop: '20px',
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },

    },
    headline: {
        color: '#ffde1d',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
    },
    content: {
        fontFamily: 'PFAgoraSlabProReg',
        fontweight: 'normal',
        fontSize: '2.6vh'
    },
    contactLogo: {
        height: '40px',
        cursor: 'pointer'
    },
    contactText: {
        cursor: 'pointer',
        fontWeight: '900',
        fontSize: 'calc(20px + (30 - 20) * ((100vw - 300px) / (1920 - 300)))',
        transition: 'color .2s ease-in-out',
        '&:hover': {
            color: '#2395b1'
        },
    }
};

class ContactUs extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#contactus')
        }
    }

    handleOnClick = (src) => {
        if (src) {
            const isMail = src.startsWith('mailto:');
            if (isMail) {
                window.open(`${src}?subject=${'Email From Website'}`, '_self');
            } else {
                window.open(src, '_blank');
            }

        }
    };

    contactTexts = (contactObj, classes) => {
        const {isVisibleFromTop} = this.props;
        return (
            <Fragment>
                {contactObj.map((contact, index) => {
                    return (
                        <Animated key={`contactText-${index}`} animationIn="slideInLeft"
                                  animationInDelay={(index + 6) * 100} isVisible={isVisibleFromTop}>
                            <Row middle={'xs'}>
                                <Col xs={3} md={1}>
                                    <img className={classes.contactLogo} src={contact.img} alt={'contactLogo'}/>
                                </Col>
                                <Col xs={9} md={11}>
                                    <p onClick={() => this.handleOnClick(contact.src)}
                                       className={classes.contactText}>{contact.text}</p>
                                </Col>
                            </Row>
                        </Animated>
                    )
                })}
            </Fragment>
        )
    };

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        return (
            <div id={'contactUs'} className={classes.root}>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>{CONTACT_US.title[pageLanguage]}</p>
                        </Col>
                    </Row>
                </Animated>
                <Row className={classes.contentContainer}>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    <Col xs={10} lg={7} className={classes.contentContainer}>
                        <div className={classes.content}>
                            {this.contactTexts(CONTACT_US.contactTexts, classes)}
                        </div>
                    </Col>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                </Row>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(ContactUs)
