const cookiei18n = {
    mainTitle: {
        rus: 'на этом веб-сайте используются файлы cookie. Используя этот веб-сайт, Вы даете согласие на обработку файлов cookie',
        eng: 'Our website uses cookies to improve your experience. You can either accept or reject using cookies.'
    },
    acceptBtn: {
        rus: 'принять',
        eng: 'Accept'
    },
    declineBtn: {
        rus: 'отказаться ',
        eng: 'Reject'
    }
}
export const GET_COOKIE_TEXT = (lang) => ({
    mainTitle: cookiei18n.mainTitle[lang],
    acceptBtn: cookiei18n.acceptBtn[lang],
    declineBtn: cookiei18n.declineBtn[lang]
})