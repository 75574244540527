import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import background from "../assets/images/bg/clipped-stone-bg-tax_np.png";
import {TAX_REBAT} from "../constants";
import classNames from "classnames";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import {
    bgType,
    decideDeviceBackground,
    decideDeviceBottomShadow,
    decideDeviceTopShadow,
    parallaxScrollOnMobile
} from "./utils/functionutils";


const styles = {
    root: {
        position: 'relative',
        boxShadow: ()=> decideDeviceTopShadow('inset 1px 14px 28px -6px rgba(0, 0, 0, 0.5)'),
        backgroundImage: () => decideDeviceBackground(bgType.DARK, background),
        backgroundSize: 'cover',
        minHeight: '100vh',
        "@media (min-width:800px)": parallaxScrollOnMobile()

    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    headline: {
        color: '#ffde1d',
        // textShadow: '6px 8px 14px rgba(0, 0, 0, .6)',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        //fontSize: props => `calc(${props.isRus ? 3 : 4}rem + (${props.isRus ? 60 : 80} - ${props.isRus ? 3 : 4}) * ((100vw - 300px) / (1920 - 300)))`,
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        // fontWeight: '900',
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
        textShadow: "2px 2px 0px #000000",
    },
    contentContainer: {
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },
    },
    content: props => ({
        fontFamily: 'PFAgoraSlabProReg',
        fontweight: 'normal',
        fontSize: `${props.isRus ? 2.4 : 2.6}vh`
    }),
    table: {
        "@media (max-width:450px)": {
            fontSize: '0.7rem'
        }
    },
    tableHead: {
        fontFamily: 'PFAgoraSlabProReg',
        "@media (max-width:450px)": {
            fontSize: '0.5rem'
        },
        backgroundColor: 'rgba(0,0,0, .2)',
        fontWeight: 'bold',
        borderTop: '0px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '0px solid black'
    },
    largeMargin: {
        "@media (min-width:1450px)": {
            marginTop: '50px',
        }
    },
    tableCell: {
        fontFamily: 'PFAgoraSlabProReg',
        "@media (max-width:450px)": {
            fontSize: '0.5rem'
        },
        borderTop: '0px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '0px solid black'
    },
    bottomShadow: {
        position: 'absolute',
        pointerEvents: 'none',
        minWidth: '100vw',
        minHeight: '100vh',
        bottom: '0px',
        boxShadow: 'inset 1px -14px 28px -6px rgba(0, 0, 0, 0.5)',
    }
};

class TaxRebate extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#taxrebate')
        }
    }

    table = () => {
        const {classes, pageLanguage} = this.props;
        return (
            <Fragment>
                <Row>
                    {TAX_REBAT.table.head[pageLanguage].map((head, index, arr) => {
                        return (
                            <Col xs={2}
                                 style={{
                                     background: index === 0 ? 'transparent' : '',
                                     borderRight: arr.length - 1 === index ? '0px solid black' : '',
                                 }}
                                 className={classes.tableHead}
                                 key={`tableHead-${index}`}><p>{head}</p></Col>
                        )
                    })}
                </Row>
                {TAX_REBAT.table.rows[pageLanguage].map((row, index) => {
                    return (
                        <Row key={`tableRow-${index}`}>
                            {row.map((cell, cellIndex, arr) => {
                                const isHighLight = cellIndex === 1 || arr.length - 1 === cellIndex;
                                return (
                                    <Col xs={2}
                                         className={classes.tableCell}
                                         style={{
                                             background: isHighLight ? '#ffde1d' : '',
                                             fontWeight: isHighLight ? 'bold' : '',
                                             borderRight: arr.length - 1 === cellIndex ? '0px solid black' : '',
                                         }}
                                         key={`tableCell-${cellIndex}`}><p>{cell}</p></Col>
                                )
                            })}
                        </Row>
                    )
                })}
            </Fragment>

        )
    };

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        return (
            <div id={'taxRebate'} className={classes.root}>
                <div className={decideDeviceBottomShadow(classes.bottomShadow)}/>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>{TAX_REBAT.title[pageLanguage]}</p>
                        </Col>
                    </Row>
                </Animated>
                <Animated animationIn="fadeInUpBig" animationInDelay={200} isVisible={isVisibleFromTop}>
                    <Row className={classes.contentContainer}>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                        <Col xs={10} lg={7} className={classes.contentContainer}>
                            <div className={classes.content}>
                                {TAX_REBAT.content[pageLanguage]}
                            </div>
                        </Col>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    </Row>
                </Animated>
                <Animated animationIn="fadeInUpBig" animationInDelay={300} isVisible={isVisibleFromTop}>
                    <Row className={classNames(classes.contentContainer, classes.largeMargin)}>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                        <Col xs={11} lg={9} className={classNames(classes.contentContainer, classes.table)}>
                            {this.table()}
                        </Col>
                    </Row>
                </Animated>
                <Animated animationIn="fadeInUpBig" animationInDelay={400} isVisible={isVisibleFromTop}>
                    <Row className={classNames(classes.contentContainer, classes.largeMargin)}>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                        <Col xs={10} lg={7} className={classes.contentContainer}>
                            <div className={classes.content}>
                                {TAX_REBAT.subContent[pageLanguage]}
                            </div>
                        </Col>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    </Row>
                </Animated>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(TaxRebate)
