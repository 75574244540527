import React, {Component, Fragment} from 'react';
import injectSheet from 'react-jss'
import {Col, Row,} from "react-flexbox-grid";
import {compose} from "redux";
import {connect} from "react-redux";
import {scrollToTopPos} from "../reducers/scroll";
import {FOOTER} from "../constants/content";
import {isMobileOnly} from 'react-device-detect';

const styles = {
    root: {
        background: '#ffde1d',
        minHeight: '40px',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'normal',
        fontSize: '0.8rem',
        boxShadow: 'inset 0px 9px 9px -4px rgba(0,0,0,0.75)',
        color: '#696969',
        zIndex: 999999,

    },
    created: {
        paddingLeft: '20px'
    },
    copy: {
        textAlign: 'right',
        paddingRight: '20px'
    },
    balazs: {
        cursor: 'pointer',
        transition: 'color .2s ease-in-out',
        '&:hover': {
            color: '#2395b1',
        },
    }
};

class Footer extends Component {

    componentDidMount() {
        document.addEventListener("scroll", () => {
            const el = document.getElementById('footer');
            const px = Math.ceil(window.innerHeight - el.getBoundingClientRect().top);
            const retPx = px < 0 ? 0 : px;
            if(px > 0){
                this.props.scrollToTopPos(retPx);
            }
        })
    }

    handleBalazs = () => () => window.open(`mailto:${FOOTER.mailSrc}?subject=${'I like the site very much: (TheScout)'}`, '_self');

    render() {
        const {classes} = this.props;
        return (
            <Fragment>
                <Row id={'footer'} middle={'xs'} className={classes.root}>
                    <Col xs={isMobileOnly ? 5 : 3} className={classes.created}>Created by: <span
                        className={classes.balazs} onClick={this.handleBalazs()}>Balázs</span></Col>
                    <Col xs={isMobileOnly ? 7 : 9} className={classes.copy}>{`Copyright © ${new Date().getFullYear()} TheScout`}</Col>
                </Row>
            </Fragment>
        )
    }
}


const mapDispatchToProps = {scrollToTopPos};

export default compose(
    injectSheet(styles),
    connect(() => ({
    }), mapDispatchToProps)
)(Footer)
