import {ENG} from "../constants";

export const ACTION_TYPES = {
    CHANGE_LAGUAGE: 'languageselector/CHANGE_LAGUAGE',
};

const initialState = {
    pageLanguage: ENG
};

// Reducer

export default (state = initialState, action = {}) => {
    if (action.type === ACTION_TYPES.CHANGE_LAGUAGE) {
        return {
            pageLanguage: action.payload
        };
    } else {
        return state;
    }
};

export const changeLanguage = (language) => {
    return async dispatch => {
        await dispatch({
            type: ACTION_TYPES.CHANGE_LAGUAGE,
            payload: language,
        });
    };
};
