import React, {Component, Fragment} from 'react';
import {AboutUs, ContactUs, Gallery, LandingPage, OurDirectors, TaxRebate, WhoWeAre} from "../containers";
import {Element} from 'react-scroll'
import ParalaxBgAboutUs from '../assets/images/parallax/thescout_0302.jpg'
import ParalaxBgTaxRabat from '../assets/images/parallax/thescout_0524.jpg'
import ParalaxBgWhoWeAre from '../assets/images/parallax/thescout_1088.jpg'
import ParalaxBgOurDirectors from '../assets/images/parallax/thescout_0859.jpg'
import ParalaxBgOurGallery from '../assets/images/parallax/thescout_0643.jpg'
import ParalaxBgOurContactUs from '../assets/images/parallax/TSC_6483.JPG'
import injectSheet from "react-jss";
import {Parallax} from "react-parallax";
import {connect} from "react-redux";
import {compose} from "redux";
import {RUS} from "../constants";
import {withRouter} from "react-router";
import DetectViewport from "../compontents/detectviewport";
import 'animate.css'
import OurWorks from "./ourworks";
import {isMobile} from 'react-device-detect';
import Footer from "./footer";

const styles = {
    root: {}

};

class OnePage extends Component {

    parallaxOnLargeScreen = (background, strength, page, elementId, noParallax) => {
        if (!isMobile || noParallax) {
            return (
                <Fragment>
                    <Parallax bgImage={background} strength={strength}>
                        <DetectViewport elementId={elementId}>
                            {page}
                        </DetectViewport>
                    </Parallax>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <DetectViewport elementId={elementId}>
                        {page}
                    </DetectViewport>
                </Fragment>
            )
        }
    };

    render() {
        const {classes, pageLanguage} = this.props;
        const isRus = pageLanguage === RUS;
        return (
            <div className={classes.root}>
                <Element name="/" className="element">
                    <DetectViewport elementId={'landingPage'}>
                        <LandingPage/>
                    </DetectViewport>
                </Element>
                <Element name="aboutus" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgAboutUs, 550, <AboutUs pageLanguage={pageLanguage}
                                                                                isRus={isRus}/>, 'aboutUs')}
                </Element>
                <Element name="taxrebate" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgTaxRabat, 100, <TaxRebate pageLanguage={pageLanguage}
                                                                                   isRus={isRus}/>, 'taxRebate')}
                </Element>
                <Element name="whoweare" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgWhoWeAre, 550, <WhoWeAre pageLanguage={pageLanguage}
                                                                                  isRus={isRus}/>, 'whoWeAre')}
                </Element>
                <Element name="ourdirectors" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgOurDirectors, 100, <OurDirectors pageLanguage={pageLanguage}
                                                                                          isRus={isRus}/>, 'ourDirectors')}
                </Element>
                <Element name="gallery" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgOurGallery, 550, <Gallery pageLanguage={pageLanguage}
                                                                                   isRus={isRus}/>, 'gallery')}
                </Element>
                <Element name="ourworks" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgOurGallery, 550, <OurWorks pageLanguage={pageLanguage}
                                                                                    isRus={isRus}/>, 'ourWorks', true)}
                </Element>
                <Element name="contactus" className="element">
                    {this.parallaxOnLargeScreen(ParalaxBgOurContactUs, 550, <ContactUs pageLanguage={pageLanguage}
                                                                                       isRus={isRus}/>, 'contactUs')}
                </Element>
                <Fragment>
                    <Footer/>
                </Fragment>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    pageLanguage: state.languageSelector.pageLanguage
});

export default compose(
    injectSheet(styles),
    withRouter,
    connect(mapStateToProps, {})
)(OnePage);
