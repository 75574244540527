import React, {Fragment} from 'react';
import address from '../assets/images/social/social_address.png'
import mail from '../assets/images/social/social_mail.png'
import linkedin from '../assets/images/social/social_linkedin.png'
import phone from '../assets/images/social/social_phone.png'
import instagram from '../assets/images/social/social_instagram_grey.png'
import linda from '../assets/images/team/Linda.jpg'
import zita from '../assets/images/team/Zita.jpg'
import monet from '../assets/images/team/monet.jpg'
import dani from '../assets/images/team/Dani.jpg'
import gergo from '../assets/images/team/gergo_res.jpg'
import miki from '../assets/images/directors/Miki.jpg'
import godaK from '../assets/images/directors/GodaK.jpg'
import domotor from '../assets/images/directors/domotor.jpg'
import otlettol from '../assets/images/works/otlettol.jpg'
import shadowOfJustice from '../assets/images/works/ShadowOfJustice.jpg'
import xibao from '../assets/images/works/Xibao.jpg'

import theLastPartisan from '../assets/images/works/comp/4theyoungestpartisan.jpg'
import eon from '../assets/images/works/comp/5eon.jpg'
import delhaize from '../assets/images/works/comp/6delhaize.jpg'
import jab from '../assets/images/works/comp/7jab_harry_.jpg'
import mtu from '../assets/images/works/comp/8mtu.jpg'
import surga from '../assets/images/works/comp/9surga_yang_.jpg'
import fabreze from '../assets/images/works/comp/10fabreze.jpg'
import raabta from '../assets/images/works/comp/11raabta.jpg'
import theMan from '../assets/images/works/comp/12the_man_with_the_i_rl6oj.jpg'
import brimstone from '../assets/images/works/comp/13brimstone.jpg'
import xbox from '../assets/images/works/comp/14xbox.jpg'
import otp from '../assets/images/works/comp/15otp.jpg'
import taco from '../assets/images/works/comp/16taco.jpg'
import mtu2 from '../assets/images/works/comp/17mtu.jpg'
import montecarlo from '../assets/images/works/comp/18montecarlo.jpg'
import nokia from '../assets/images/works/comp/19nokia.jpg'
import suzuki from '../assets/images/works/comp/20suzuki.jpg'
import skype from '../assets/images/works/comp/21skype.jpg'
import telekom from '../assets/images/works/comp/22telekom.jpg'
import greygouse from '../assets/images/works/comp/23greygouse.jpg'
import samsung from '../assets/images/works/comp/24samsung.jpg'
import jd from '../assets/images/works/comp/25jd.jpg'
import dolmio from '../assets/images/works/comp/26dolmio.jpg'
import jd2 from '../assets/images/works/comp/27jd.jpg'
import mtv from '../assets/images/works/comp/mtv_3.png'
import aranyaszok from '../assets/images/works/comp/29aranyaszok.jpg'
import russiatolov from '../assets/images/works/comp/russiatolov.jpg'
import {history} from "../index";
import * as Scroll from "react-scroll";


export const ABOUT_US = {
    title: {
        rus: 'О КОМПАНИИ',
        eng: 'About us'
    },
    content: {
        rus: <Fragment>
            <p>
                <strong>TheScout</strong> независимая компания по поиску локаций и продакшн-сервису, основанная в 2012
                году и базирующаяся в Будапеште.
            </p>
            <p>
                Благодаря нашей обширной базе данных из более чем 8000 мест (внутри и вне помещений) по всей Венгрии и в
                соседних странах, мы стали ведущей компанией по поиску локаций в стране. Приблизительно 80% всех
                проектов кино и рекламы в первую очередь прибегают к нашим услугам когда речь заходит о съёмках в
                Венгрии.
            </p>
            <p>
                С момента своего основания TheScout участвовал в локейшн-скаутинге для более чем 45 художественных
                фильмов и сериалов, сотни рекламных роликов. Мы смогли изучить системы продакшн-сервиса различных
                проектов, вплоть до таких, съёмочный процесс которых длился 6 месяцев. Благодаря этому мы смогли
                ознакомиться с различными потребностями клиентов из Азии, Европы или США.
            </p>
            <p>
                Многочисленный опыт делает TheScout успешной в предоставлении услуг продакшн-сервиса. Совместно с
                профессионалами с которыми работаем вместе все эти годы мы создали высококвалифицированную и опытную
                производственную команду с прочными деловыми связями (локации, студии, аренда оборудования, жильё),
                чтобы удовлетворить все ваши потребности и обеспечить первоклассное качество.
            </p>
            <p>
                С TheScout вы будете непосредственно у источника, получая как пакет локаций, так и коммерческое
                предложение для своего проекта в зависимости от ваших конкретных потребностей. Всё, что нам нужно от вас
                – «доска настроения» (moodboard) и базовые сведения. Мы предоставим пакет локаций, включая лучшие
                варианты для съёмок, а также разумный бюджет.
            </p>
        </Fragment>,
        eng: <Fragment>
            <p>
                <strong>TheScout</strong> is a Budapest based, independent location management & service production
                company founded in 2012.
            </p>
            <p>
                With our extensive database of over <strong>8000 locations</strong> (interiors and exteriors) all over
                Hungary as well as in the neighbouring states, we have become the leading scout company in the country.
                Approx. <strong>80% of all commercial and movie projects </strong>
                call on our services first, when they want to produce in Hungary.
            </p>
            <p>
                Since its founding, TheScout has been involved in the location management <strong>of over 45 feature
                films or series</strong> as well as <strong>hundreds of commercials</strong>.
                We were able to examine the various service production systems, from commercials all the way to 6-month
                long series, getting familiar with the different needs of clients from Asia, Europe or the US.
            </p>
            <p>
                These multiple experiences make TheScout successful in service production. By involving professionals we
                have been working together with all these years, we have formed a highly skilled and <strong>experienced
                production team</strong> with a solid business networks (locations, studios, rental houses or
                accommodation) in order to fulfil all your needs and to deliver first-class quality.
            </p>
            <p>
                <strong>With TheScout you will be directly at the source,</strong> receiving both a location package and
                quote for your project, all based on your specific needs. All we need from you is a moodboard and some
                basic information and we will deliver a location package including <strong>the best
                shooting</strong> options as well as a <strong>reasonable budget.</strong>
            </p>
        </Fragment>
    }
};

export const TAX_REBAT = {
    title: {
        rus: 'НАЛОГОВЫЕ ЛЬГОТЫ',
        eng: 'TAX REBATE'
    },
    table: {
        head: {
            rus: ['', 'БЮДЖЕТ, ПОТРАЧЕННЫЙ В ВЕНГРИИ', 'БЮДЖЕТ, ПОТРАЧЕННЫЙ НЕ В ВЕНГРИИ', 'ОСНОВА ДЛЯ ЛЬГОТЫ', 'НАЛОГОВАЯ ЛЬГОТА (30%)'],
            eng: ['', 'BUDGET SPENT IN HUNGARY', 'BUDGET NOT SPENT IN HUNGARY', 'BASIS OF THE REBATE', 'TAX REBATE (30%)']
        },
        rows: {
            rus: [
                ['Вариант 1', 'Евро 1.000.000', 'Евро 0', 'Евро 1.000.000', 'Евро 300.000'],
                ['Вариант 2', 'Евро 800.000', 'Евро 200.000', 'Евро 1.000.000', 'Евро 300.000'],
                ['Вариант 3', 'Евро 600.000', 'Евро 400.000', 'Евро 750.000', 'Евро 225.000'],
                ['Вариант 4', 'Евро 200.000', 'Евро 800.000', 'Евро 250.000', 'Евро 75.000'],
            ],
            eng: [
                ['Variant 1', 'EUR 1.000.000', 'EUR 0', 'EUR 1.000.000', 'EUR 300.000'],
                ['Variant 2', 'EUR 800.000', 'EUR 200.000', 'EUR 1.000.000', 'EUR 300.000'],
                ['Variant 3', 'EUR 600.000', 'EUR 400.000', 'EUR 750.000', 'EUR 225.000'],
                ['Variant 4', 'EUR 200.000', 'EUR 800.000', 'EUR 250.000', 'EUR 75.000'],
            ]
        }
    },
    content: {
        rus: <Fragment>
            <p>
                Если у вас телевизионный фильм / сериал / художественный фильм / анимационный фильм / короткометражный
                фильм или документальный проект, венгерское государство предлагает льготу в размере 30% на основании
                возникших расходов. Более того, вы можете потратить 20% бюджета за пределами Венгрии и по-прежнему иметь
                право на льготу в размере 30%.
            </p>
        </Fragment>,
        eng: <Fragment>
            <p>
                If you have a TV-film / series / feature film / animation / short movie or documentary
                project, the Hungarian state offers a <strong>30% tax</strong> rebate based on its emerged
                expenditure. Moreover, you <strong>can spend 20% of this budget outside Hungary </strong>
                and still be eligible for the 30% tax rebate.
            </p>
        </Fragment>
    },
    subContent: {
        rus: <Fragment>
            <p>
                Для получения дополнительной информации (подробности процесса, приемлемые расходы, исключения или
                ограничения) свяжитесь с нами, и мы объясним подробности.
            </p>
        </Fragment>,
        eng: <Fragment>
            <p>
                For further information (details of the process, eligible expenses, excluded or limited items)
                <strong className={'text-link'} onClick={() => {
                    Scroll.scroller.scrollTo("contactus", {
                        smooth: true,
                    });
                    history.push('/#contactus')
                }}> contact us</strong> and we will guide you through it.
            </p>
        </Fragment>
    }
};

export const WHO_WE_ARE = {
    title: {
        rus: 'НАША КОМАНДА',
        eng: 'OUR TEAM'
    },
    persons: [{
        picture: gergo,
        name: 'Gergo Bakos',
        imdb: true,
        imdbSrc: 'https://www.imdb.com/name/nm2737978/?ref_=nv_sr_1',
        title: {
            rus: '',
            eng: 'Producer'
        }
    }, {
        picture: dani,
        name: 'Daniel Gudmon',
        imdb: true,
        imdbSrc: 'https://www.imdb.com/name/nm7186664/?ref_=nv_sr_1',
        title: {
            rus: '',
            eng: 'Line Producer'
        }
    }, {
        picture: linda,
        name: 'Linda Mester',
        imdb: true,
        imdbSrc: 'https://www.imdb.com/name/nm2332077/?ref_=nv_sr_1',
        title: {
            rus: '',
            eng: 'Production Manager'
        }
    }, {
        picture: zita,
        name: 'Zita Bognar',
        imdb: true,
        imdbSrc: 'https://www.imdb.com/name/nm5249336/?ref_=nv_sr_1',
        title: {
            rus: '',
            eng: 'Head of Finance'
        }
    }, {
        picture: monet,
        name: 'Monet Au',
        imdb: false,
        title: {
            rus: '',
            eng: 'Representative, Hong Kong'
        }
    }/*, {
        picture: benHeng,
        name: 'Ben Heng',
        imdb: false,
        title: {
            rus: '',
            eng: 'Representative, Asia-Pacific'
        }
    }*/]
};

export const OUR_DIRECTORS = {
    title: {
        rus: 'НАШИ РЕЖИССЁРЫ ',
        eng: 'DIRECTORS WE LIKE'
    },
    persons: [{
        picture: godaK,
        name: 'Kriszta Goda',
        play: true,
        videoProfile: true,
        videoUrl: "https://vimeo.com/krisztinagoda",
        web: true,
        webSrc: 'https://filmdirector.hu',
        title: {
            rus: '',
            eng: 'Director'
        }
    }, /*{
        picture: daphne,
        name: 'Daphne Samaras',
        darkTheme: true,
        play: true,
        videoUrl: "https://vimeo.com/144075923",
        web: true,
        webSrc: 'http://daphnesamaras.com',
        title: {
            rus: '',
            eng: 'Director'
        }
    },*/ {
        picture: domotor,
        name: 'Tamas Domotor',
        play: true,
        imdb: true,
        imdbSrc: 'https://www.imdb.com/name/nm1570603/?ref_=fn_al_nm_1',
        videoUrl: "https://vimeo.com/262200321",
        title: {
            rus: '',
            eng: 'Director'
        }
    }, {
        picture: miki,
        name: 'Miki357',
        darkTheme: true,
        play: true,
        imdb: false,
        videoProfile: true,
        //imdbSrc: 'https://www.imdb.com/name/nm0000216',
        videoUrl: "https://vimeo.com/miki357",
        title: {
            rus: '',
            eng: 'Director'
        }
    }]
};


function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../assets/images/gallery', false, /\.(png|jpe?g|jpg|svg)$/));
const thumbsImages = importAll(require.context('../assets/images/gallery/thumbs', false, /\.(png|jpe?g|jpg|svg)$/));

export const GALLERY = {
    title: {
        rus: 'ГАЛЕРЕЯ',
        eng: 'GALLERY'
    },
    showMore: {
        rus: 'показать больше',
        eng: 'Show more'
    },
    instagram: {
        rus: 'Следуйте за нами в Instagram',
        eng: 'Follow us on instagram'
    },
    instagramSrc: 'https://www.instagram.com/thescout.hu',
    gallery: images.map(imgUrl => {
        return ({
            thumb: thumbsImages.find(thumb => {
                const f = imgUrl.split('/');
                const f2 = f[f.length - 1].split('.')[0];

                const t = thumb.split('/');
                const t2 = t[t.length - 1].split('.')[0].replace('_thumbTS', '');

                return f2 === t2
            }), fullRez: imgUrl
        });
    })
};

export const CONTACT_US = {
    title: {
        rus: 'КОНТАКТЫ',
        eng: 'CONTACT US'
    },
    contactTexts: [{
        img: address,
        text: 'H-1085 Budapest, Stahly utca 1',
        src: 'https://goo.gl/maps/7czJAv9cziB2'
    }, {
        img: mail,
        text: 'info@thescout.hu',
        src: 'mailto:info@thescout.hu'
    }, {
        img: phone,
        text: '+36707728653'
    }, {
        img: linkedin,
        text: 'LinkedIn/theScout',
        src: 'https://www.linkedin.com/company/11565527'
    }, {
        img: instagram,
        text: 'Instagram',
        src: 'https://www.instagram.com/thescout.hu'
    },
    ],

    contacts: [{
        picture: '',
        name: 'Contact Person1',
        email: 'contact@person.com',
        tel: '+36301234567',
        linkedIn: 'https://linkedin.com',
        skype: 'https://skype.com'
    }, {
        picture: '',
        name: 'Contact Person2',
        email: 'contact2@person.com',
        tel: '+36301234567',
        linkedIn: 'https://linkedin.com',
        skype: 'https://skype.com'
    }, {
        picture: '',
        name: 'Contact Person3',
        email: 'contact3@person.com',
        tel: '+36301234567',
        linkedIn: 'https://linkedin.com',
        skype: 'https://skype.com'
    }, {
        picture: '',
        name: 'Contact Person4',
        email: 'contact4@person.com',
        tel: '+36301234567',
        linkedIn: 'https://linkedin.com',
        skype: 'https://skype.com'
    }]
};

export const FOOTER = {
    mailSrc: 'osgyanb@gmail.com'
};

export const OUR_WORKS = {
    title: {
        rus: 'ПРОЕКТЫ',
        eng: 'Works'
    },
    works: [{
        picture: russiatolov,
        title: 'To Russia with Love',
        year: '2022',
        descriptionTexts: [
            {producer: 'Erwin Blanco'},
            {director: 'Veronica Velasco'},
            {dop: 'Marvin Reyes'},
            {serviceProduction: 'TheScout'},
            {location: 'Budapest, The Philippines'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.imdb.com/title/tt23143150/reference",
    },{
        picture: mtv,
        title: 'Főmenü',
        year: '2022-',
        descriptionTexts: [
            {director: 'Tamás Dömötör'},
            {dop: 'Milán Cosmic'},
            {serviceProduction: 'TheScout'},
            {location: 'Budapest'},
        ],
        play: true,
        dark: true,
        isDirectVideoUrl: true,
        videoUrl: "https://mediaklikk.hu/fomenu/",
    }, {
        picture: shadowOfJustice,
        title: 'Shadow of Justice',
        year: '2018',
        descriptionTexts: [
            {producer: 'Tommy Leung'},
            {director: 'Hong Kam Put & Tsang Man Shan'},
            {dop: 'Woo Alpheus & Tsang Yuk Mui'},
            {serviceProduction: 'TheScout'},
            {location: 'Budapest, Hong Kong, Malaysia'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: " https://www.imdb.com/title/tt13276196/reference",


    }, {
        picture: xibao,
        title: 'Xi Bao',
        year: '2018',
        descriptionTexts: [
            {producer: 'ZHANG Rixin'},
            {director: 'WANG Danyang'},
            {dop: 'Lam Kwok Wa'},
            {serviceProduction: 'TheScout'},
            {location: 'Budapest, Beijing'},
        ],
        play: false,
        videoUrl: "",


    }, {
        picture: otlettol,
        title: 'Ötlettől a milliókig ',
        year: '2013',
        descriptionTexts: [
            {director: 'Domotor Tamas, Meggyes Krisztina, Haragonics Sara'},
            {dop: 'Istvan Szonyi, Dani Beran-Wallot'},
            {production: 'TheScout'},
            {location: 'Hungary'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.youtube.com/watch?v=XEkFd7csO1w",


    }, {
        picture: theLastPartisan,
        title: 'The Last Partisan',
        year: '2015',
        descriptionTexts: [
            {director: 'Hidai Goldsmith'},
            {dop: 'Yonathan Dolan'},
            {production: 'TheScout'},
            {location: 'Hungary, Slovakia, NYC'},
        ],
        play: false,
        isDirectVideoUrl: false,
        videoUrl: "",
    }, {
        picture: eon,
        title: 'EON',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/241867594",
    }, {
        picture: delhaize,
        title: 'Delhaize 150 Christmases',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Orchestra'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/244687818",
    }, {
        picture: jab,
        title: 'Jab Harry Met Sejal ',
        year: '2017',
        descriptionTexts: [
            {production: 'Feature, PPM'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.imdb.com/videoplayer/vi1389738265",
    }, {
        picture: mtu,
        title: 'MTU',
        year: '',
        descriptionTexts: [
            {production: 'TVC, FocusFox'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.youtube.com/watch?v=saxsdxzBiQ4",
    }, {
        picture: surga,
        title: 'Surga Yang Tak Dirindukan',
        year: '2017',
        descriptionTexts: [
            {production: 'Feature, Filmreaktor'},
        ],
        play: false,
        isDirectVideoUrl: false,
        videoUrl: "",
    }, {
        picture: fabreze,
        title: 'Fabreze ONE',
        year: '2017',
        descriptionTexts: [
            {production: 'TVC, Orchestra'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.youtube.com/watch?v=fYjyz7Q8_zI",
    }, {
        picture: raabta,
        title: 'Raabta',
        year: '2017',
        descriptionTexts: [
            {production: 'Feature, PPM'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.imdb.com/videoplayer/vi2816260633?ref_=ttvi_vi_imdb_1",
    }, {
        picture: theMan,
        title: 'The Man with the Iron Heart ',
        year: '2017',
        descriptionTexts: [
            {production: 'Feature, ASP'},
        ],
        play: false,
        isDirectVideoUrl: false,
        videoUrl: "",
    }, {
        picture: brimstone,
        title: 'Brimstone',
        year: '',
        descriptionTexts: [
            {production: 'Feature, Proton'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.imdb.com/videoplayer/vi622573081",
    }, {
        picture: xbox,
        title: 'Xbox',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/300985043",
    }, {
        picture: otp,
        title: 'OTP',
        year: '',
        descriptionTexts: [
            {production: 'TVC'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.youtube.com/watch?v=EugeTldc330",
    }, {
        picture: taco,
        title: 'Taco Bell',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/281237087",
    }, {
        picture: mtu2,
        title: 'MTU',
        year: '',
        descriptionTexts: [
            {production: 'TVC, FocusFox'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://videa.hu/videok/nagyvilag/magyarorszag-rad-var-magyar-turisztikai-ugynokseg-ggTnaief7x1oUYNI",
    }, {
        picture: montecarlo,
        title: 'Monte Carlo TVC',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Orchestra'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://www.youtube.com/watch?v=ZYx4j-UsXa0",
    }, {
        picture: nokia,
        title: 'Nokia',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/260766445",
    }, {
        picture: suzuki,
        title: 'Suzuki',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Cydfilms'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/191381168",
    }, {
        picture: skype,
        title: 'Skype',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Orchestra'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/144556663",
    }, {
        picture: telekom,
        title: 'Telekom – Feel connected',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Strawberry'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/176710496",
    }, {
        picture: greygouse,
        title: 'Grey Gouse',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/249516177",
    }, {
        picture: samsung,
        title: 'Samsung – See colors',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Cydfilms'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/168934506",
    }, {
        picture: nokia,
        title: 'Nokia',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/260766445",
    }, {
        picture: jd,
        title: 'JD Williams – Christmas',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Strawberry'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/151633496",
    }, {
        picture: dolmio,
        title: 'Dolmio',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Pioneer'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/241871594",
    }, {
        picture: jd2,
        title: 'JD Williams – Autumn',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Strawberry'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/151633202",
    }, {
        picture: aranyaszok,
        title: 'Arany Ászok',
        year: '',
        descriptionTexts: [
            {production: 'TVC, Strawberry'},
        ],
        play: true,
        isDirectVideoUrl: true,
        videoUrl: "https://vimeo.com/150796804",
    },]
};
