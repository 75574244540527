import React, {Component, Fragment} from 'react';
import classNames from "classnames";
import injectSheet from 'react-jss'
import {changeLanguage} from "../reducers/languageselector";
import {CHANGE_LANGUAGE, ENG, RUS, SCROLL_TO_TOP} from "../constants";
import ReactTooltip from 'react-tooltip'
import rusFlag from '../assets/svg/flags/rus.svg'
import engFlag from '../assets/svg/flags/eng.svg'
import * as Scroll from 'react-scroll';
import {Link} from 'react-scroll';
import upArrow from "../assets/svg/circle-arrow.svg";
import theScoutLogo from "../assets/images/logo_y.png"
import burgermenu from "../assets/svg/bars-solid.svg"
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Breakpoint from 'react-socks';
import {Col, Row} from "react-flexbox-grid";
import BurgerMenu from "./burgermenu";
import {scrollToTopPos} from "../reducers/scroll";

const styles = {
    navbar: {
        zIndex: '999',
        position: 'fixed',
        top: '0px',
        minWidth: '100vw',
        paddingBottom: '2vh',
        paddingTop: '2vh',
        // paddingLeft: '1vw',
        //transition: 'all .5s ease-in-out',
        fontFamily: 'PFAgoraSlabProReg',
        fontWeight: 'normal',
        /* height: '40px',
         minWidth: '100vw',
         backgroundColor: 'rgba(0, 0, 0, 0)',
         zIndex: '999',
         position: 'fixed',
         top: '0px',
         display: 'flex',
         justifyContent: 'flex-end',
         alignItems: 'center',*/
    },
    navLinks: {
        //color: '#ffde1d',
        color: '#fff',
        opacity: '.7',
        margin: '0px',
        // fontSize: 'calc(1.2rem + (2.7 - 1.2) * ((100vw - 1366px) / (1920 - 1366)))',
        //fontWeight: 'bolder',
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'opacity .2s ease-in-out',
        '&:hover': {
            opacity: '1',
            //textShadow: '4px 4px 5px rgba(150, 150, 150, .75)'
        }
    },
    activeLink: {
        // transition: 'all .2s ease-in-out',
        opacity: '1',
        color: '#ffde1d',
        //textShadow: '4px 4px 5px rgba(150, 150, 150, .75)'
    },
    flag: {
        //marginRight: '20px',
        cursor: 'pointer',
        //transition: 'all .2s ease-in-out',
        '&:hover': {
            //    boxShadow: '4px 4px 5px rgba(150, 150, 150, .75)'
        }
    },
    upArrowContainer: {
        position: 'fixed',
        zIndex: '99',
       // bottom: '5px',
        minWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        animation: 'UpandDown-animation 1.2s ease-in-out infinite normal',
    },
    upArrow: {
        opacity: '.3',
        cursor: 'pointer',
        animation: 'FadeIn-animation 450ms ease-in-out forwards normal',
        '&:hover': {
            transition: 'all .2s ease-in-out',
            opacity: '1'
        }
    },
    logo: {
        position: 'absolute',
        marginLeft: '1vw',
        height: '65px',
        opacity: '.7',
        transitionDelay: '.5s',
        transition: 'all .2s ease-in-out',
    },
    logoSmall: {
        position: 'absolute',
        marginLeft: '1vw',
        height: '30px',
        opacity: '.7',
        transition: 'all .2s ease-in-out',
        marginTop: '-0.6vh',
        marginBottom: '1vh',
        cursor: 'pointer'
    },
    burgerMenu: {
        paddingRight: '5vw'
    },
    mobileMenuRow: {
        position: 'fixed',
        margin: '0px',
        zIndex: '9',
        minWidth: '100vw',
        backgroundColor: 'rgba(0,0,0,0.9)'
    },
    mobileNavLang: {
        display: 'flex',
        justifyContent: 'center'
    },
    socialContainer: {
        position: 'absolute',
        right: '50%',
        top: '10px'
    },
    socialLogo: {
        cursor: 'pointer',
        opacity: '.4',
        marginRight: '10px',
        transition: 'opacity .2s ease-in-out',
        '&:hover': {
            opacity: '.7'
        }
    },
    [`@keyframes UpandDown-animation`]: {
        '0%': {transform: 'translateY(2px)'},
        '50%': {transform: 'translateY(-2px)'},
        '100%': {transform: 'translateY(2px)'},
    },
    [`@keyframes FadeIn-animation`]: {
        '0%': {opacity: '0'},
        '100%': {opacity: '.3'},
    },
};


class TransparentNavbar extends Component {

    state = {activeLink: null, transparentNav: true, isBurgerOpen: false};

    componentDidMount() {
        const {location, navs} = this.props;
        this.setAvtiveLinkViaUrl(navs);
        const hashLocation = location.hash.replace('#', '');
        this.scrolToElement(hashLocation !== "" ? hashLocation : "/");
        document.addEventListener('keydown', this.onKeyPress);
        document.addEventListener('scroll', this.onScroll);
    };

    onScroll = () => {
        this.setState({
            transparentNav: window.scrollY < 1
        })
    };
    setAvtiveLinkViaUrl = (navs) => {
        const {history} = this.props;
        let activeIndex = navs.findIndex(nav => `#${nav.src}` === history.location.hash);
        if (history.location.hash === '') {
            activeIndex = 0
        }
        this.setState({activeLink: activeIndex})
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.history.location.hash !== this.props.location.hash) {
            this.setAvtiveLinkViaUrl(this.props.navs)
            this.props.scrollToTopPos(7)
        }
    }

    scrolToElement = (el) => {
        Scroll.scroller.scrollTo(el, {
            smooth: true,
        });
    };
    scrollToTop = () => {
        Scroll.animateScroll.scrollToTop();
        this.props.history.push('/');
        this.setState({activeLink: null})
    };

    onKeyPress = (e) => {
        const {history, location, navs} = this.props;
        const hashLocation = location.hash.replace('#', '');
        const isDown = e.keyCode === 40;
        const isUp = e.keyCode === 38;

        navs.some((nav, index, arr) => {
            const preventArrOut = isDown ? arr.length > index + 1 : isUp ? index - 1 >= 0 : false;
            if (location.hash === "" || (hashLocation === nav.src && preventArrOut)) {
                const upOrDown = isDown ? 1 : -1;
                const nextIndex = index + upOrDown;
                const notMinIndex = nextIndex >= 0 ? nextIndex : 0;
                const nextLocation = arr[notMinIndex].src;
                this.scrolToElement(nextLocation);
                const isHome = nextLocation === "/";
                setTimeout(() => {
                    history.push(`${!isHome ? '/#' : ''}${nextLocation}`);
                }, 1000);
                return true
            }
            return false
        });
    };

    handleRouteClick = (src, i) => () => {
        this.setState({
            activeLink: i
        });
        const isHome = src === "/";
        this.props.history.push(`${!isHome ? '#' : ''}${src}`)

    };

    handleLanguageChange = () => () => {
        const {pageLanguage, changeLanguage} = this.props;
        const changeTo = pageLanguage === RUS ? ENG : RUS;
        changeLanguage(changeTo)
    };

    handleBurgerOpen = (state) => {
        if (state && !state.isOpen) {
            this.setState({isBurgerOpen: false})
        } else {
            this.setState({isBurgerOpen: true})
        }
    };

    flag = (height = 20) => {
        const {pageLanguage, classes} = this.props;
        const currentFlag = pageLanguage === ENG ? rusFlag : engFlag;
        return <img height={height}
                    data-tip data-for='flag'
                    className={classes.flag}
                    src={currentFlag}
                    onClick={this.handleLanguageChange()}
                    alt={'flag'}/>

    };

    render() {
        const {classes, navs, pageLanguage, location} = this.props;
        const {transparentNav, isBurgerOpen, activeLink} = this.state;
        const isEng = pageLanguage === ENG;
        return (
            <Fragment>
                <Breakpoint large up>
                    <header style={{backgroundColor: 'rgba(0, 0, 0, .7)'}} className={classes.navbar}>
                        <Row>
                            <Col xs={1}>
                                <Row middle={'xs'}>
                                    <Col xs={12}>
                                        <img onClick={() => this.scrollToTop()}
                                             className={transparentNav ? classes.logo : classes.logoSmall}
                                             src={theScoutLogo} alt={'logo'}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={10} style={{paddingRight: '2vw'}}>
                                <Row center={'xs'}>
                                    {navs.map((nav, index, arr) => (
                                        <Col key={`navLink-${index}`}>
                                            <Link activeClass="active"
                                                  to={nav.src}
                                                  spy={true}
                                                  isDynamic={true}
                                                  smooth={true}>
                                                <p className={classNames(classes.navLinks, activeLink === index ? classes.activeLink : '')}
                                                   style={{
                                                       paddingRight: arr.length - 1 > index ? `${isEng ? 3 : 1}vw` : '',
                                                       fontSize: `calc(${isEng ? 1.2 : 1}rem + (${isEng ? 2.7 : 2} - ${isEng ? 1.2 : 1}) * ((100vw - 1366px) / (1920 - 1366)))`,
                                                   }}
                                                   onClick={this.handleRouteClick(nav.src, index)}>
                                                    {nav.title}
                                                </p>
                                            </Link>
                                        </Col>
                                    ))}

                                </Row>
                            </Col>
                            <Col xs={1}>
                                <Row center={'xs'}>
                                    <Col>
                                        {this.flag()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <ReactTooltip id='flag'>
                            <span>{CHANGE_LANGUAGE[pageLanguage]}</span>
                        </ReactTooltip>
                    </header>
                </Breakpoint>

                <Breakpoint medium down>
                    <Row className={classes.mobileMenuRow} middle={'xs'}>
                        <Col xs={4} style={{paddingLeft: '5vw', paddingTop: '5px'}}>
                            <img onClick={() => this.scrollToTop()}
                                 src={theScoutLogo}
                                 height={40}
                                 alt={'logo'}/>
                        </Col>
                        <Col xs={4} className={classes.mobileNavLang}>
                            {this.flag()}
                        </Col>
                        <Col xs={4}>
                            <Row end={'xs'}>
                                <img className={classes.burgerMenu} src={burgermenu} height={40} alt={'burgermenu'}
                                     onClick={() => this.handleBurgerOpen()}/>
                            </Row>
                        </Col>
                    </Row>
                    <BurgerMenu isOpen={isBurgerOpen}
                                handleOpen={this.handleBurgerOpen}
                                activeLink={activeLink}
                                navs={navs}
                                isEng={isEng}
                                flag={this.flag}/>
                </Breakpoint>
                <div className={classes.upArrowContainer} style={{bottom: `${this.props.pageScrollToTop + 5}px`}}>
                    <img
                        onClick={() => this.scrollToTop()}
                        className={classes.upArrow}
                        style={{display: !location.hash ? 'none' : ''}}
                        height={30}
                        src={upArrow}
                        data-tip data-for='scrollToTop'
                        alt={'upArrow'}/>
                </div>
                <ReactTooltip id='scrollToTop'>
                    <span>{SCROLL_TO_TOP[pageLanguage]}</span>
                </ReactTooltip>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    pageLanguage: state.languageSelector.pageLanguage,
    pageScrollToTop: state.scroll.scrollPx
});

const mapDispatchToProps = {changeLanguage, scrollToTopPos};

export default compose(
    injectSheet(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TransparentNavbar);
