import React, {Component, Fragment} from 'react';
import './App.css';
import {Route, Router, Switch} from "react-router-dom";
import {TransparentNavbar} from "./compontents";
import {NAV_CONSTANT} from "./constants";
import {OnePage} from "./containers";
import {connect} from "react-redux";
import * as Scroll from "react-scroll";
import {Events} from "react-scroll";
import {history} from "./index"
import {CookieConsent, Cookies} from "react-cookie-consent";
import ReactGA from "react-ga4";
import {GET_COOKIE_TEXT} from "./constants/cookie.constants";


let scrollY = 0;

class App extends Component {
    componentDidMount() {
        //smooth Scroll
        /*const isIE = !!document.documentMode;
        if (!isIE) {
            document.getElementById('root').addEventListener('wheel', this.onWheel);
            Events.scrollEvent.register('end', function () {
                scrollY = 0
            });
        }*/

    }

    componentWillUnmount() {
        const isIE = !!document.documentMode;
        if (!isIE) {
            Events.scrollEvent.remove('end');
        }

    }

    onWheel = (e) => {
        // e.preventDefault();
        const scrollHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * 0.25;
        // scroll Up
        if (e.deltaY < 0) {
            scrollY -= scrollHeight;
        }
        //scroll Down
        if (e.deltaY > 0) {
            scrollY += scrollHeight;
        }
        setTimeout(() => {
            Scroll.animateScroll.scrollMore(scrollY);
        }, 25)
    };

    handleAcceptCookie = () => {
        ReactGA.initialize("G-PK1HPVZJV1");
    };

    handleDeclineCookie = () => {
        // remove google analytics cookies
        const domain = process.env.NODE_ENV === 'production' ? `.${window.location.hostname}` : 'localhost';
        Cookies.remove('_ga', {path: '', domain});
        Cookies.remove('_ga_PK1HPVZJV1', {path: '', domain});
        Cookies.remove('_gat', {path: '', domain});
        Cookies.remove('_gid', {path: '', domain});

    };

    render() {
        const {pageLanguage} = this.props;

        return (
            <Fragment>
                <Router history={history}>
                    <Fragment>
                        <TransparentNavbar navs={NAV_CONSTANT(pageLanguage)}/>
                        <Switch>
                            <Route path={"/"} component={OnePage}/>
                        </Switch>
                    </Fragment>
                </Router>
                <CookieConsent
                    enableDeclineButton
                    expires={14}
                    style={{backgroundColor: 'rgba(0,0,0,0.7)'}}
                    contentStyle={{color: '#ffde1d', fontSize: '14px'}}
                    buttonStyle={{backgroundColor: '#ffde1d', color: 'black'}}
                    declineButtonStyle={{backgroundColor: '#a80909',}}
                    declineButtonText={GET_COOKIE_TEXT(pageLanguage).declineBtn}
                    buttonText={GET_COOKIE_TEXT(pageLanguage).acceptBtn}
                    onAccept={this.handleAcceptCookie}
                    onDecline={this.handleDeclineCookie}
                >
                    {GET_COOKIE_TEXT(pageLanguage).mainTitle}
                </CookieConsent>
            </Fragment>
        );
    }
}

export default connect((state) => ({pageLanguage: state.languageSelector.pageLanguage}), {})(App);
