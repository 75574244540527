import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import background from "../assets/images/bg/clipped-stone-bg-who_v3.png";
import {WHO_WE_ARE} from "../constants/content";
import Card from "../compontents/card";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import {bgType, decideDeviceBackground} from "./utils/functionutils";
import {isMobile} from "react-device-detect";

const styles = {
    root: {
        backgroundImage: () => decideDeviceBackground(bgType.LIGHT, background),
        backgroundSize: 'cover',
        minHeight: '100vh',
    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    headline: {
        color: '#ffde1d',
        // textShadow: '6px 8px 14px rgba(0, 0, 0, .6)',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        //fontSize: props => `calc(${props.isRus ? 3 : 4}rem + (${props.isRus ? 60 : 80} - ${props.isRus ? 3 : 4}) * ((100vw - 300px) / (1920 - 300)))`,
        // fontWeight: '900',
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
    },
    contentContainer: {
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },
        marginTop: '30px'
    }
};

class WhoWeAre extends Component {

    state = {personCardMaxHeight: 0};

    componentDidMount() {
        const galleryTop = document.getElementById('personCardsRow').offsetTop;
        const InnerHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        if (!isMobile) {
            this.setState({personCardMaxHeight: (InnerHeight - galleryTop) - 20})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#whoweare')
        }
    }

    personCard = () => {
        const {isVisibleFromTop} = this.props;
        return (
            <Fragment>
                {WHO_WE_ARE.persons.map((person, index) => {
                    return (
                        <Col xs={12} md={6} key={`person-${index}`}>
                            <Animated animationIn="flipInX" animationInDelay={(index + 8) * 100}
                                      isVisible={isVisibleFromTop}>
                                <Card
                                    picture={person.picture}
                                    name={person.name}
                                    title={person.title.eng}
                                    imdb={person.imdb}
                                    imdbSrc={person.imdbSrc}
                                />
                            </Animated>
                        </Col>

                    )
                })}
            </Fragment>
        )
    };

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        const {personCardMaxHeight} = this.state;
        const scrollStyle = !isMobile ? {maxHeight: `${personCardMaxHeight}px`, overflowY: 'scroll', overflowX: 'hidden'} : {};

        return (
            <div id={'whoWeAre'} className={classes.root}>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>{WHO_WE_ARE.title[pageLanguage]}</p>
                        </Col>
                    </Row>
                </Animated>
                <Row className={classes.contentContainer}>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    <Col xs={10} lg={7} className={classes.contentContainer}>
                        <Row center={'lg'} id={'personCardsRow'} style={scrollStyle}>
                            {this.personCard()}
                        </Row>
                    </Col>
                    <Col xs={1} lg={0} className={'hideOnLarge'}/>
                </Row>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(WhoWeAre)
