import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import LazyLoad from 'react-lazy-load';
import {LoadingComponent} from "./index";
import {Row} from "react-flexbox-grid";

const styles = {
    root: {
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        height: '20vh',
        width: '100%',
        marginBottom: '2vh',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '10px',
        transition: 'transform .2s ease-in-out, filter .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
            filter: `blur(3px) grayscale(100%)`,
        },
        animation: 'FadeIn-animation 450ms ease-in-out forwards normal',
    },
    modalBg: {
        background: 'rgba(255, 255, 255, .24)',
        borderRadius: '10px',
        maxWidth: '75vw'
    },
    searchIcon: {
        pointerEvents: 'none',
        display: 'none',
        position: 'absolute',
        zIndex: '3',
        top: '25%',
        left: '25%',
        opacity: '.75',
        height: '50%',
        width: '50%',
    },
    loading: {
        height: '100%',
        width: '100%',
    },

    [`@keyframes FadeIn-animation`]: {
        '0%': {opacity: '0', transform: 'scale(0.5)'},
        '100%': {opacity: '1', transform: 'scale(1)'},
    },
};

class OwnGallery extends Component {

    state = {isVisible: false};

    handleVisible = () => {
        this.setState({isVisible: true})
    };

    render() {
        const {classes, thumb, id, handleOpen, index} = this.props;
        const {isVisible} = this.state;

        return (
            <Fragment>
                <div className={classes.root}>
                    <LazyLoad height={'100%'} onContentVisible={this.handleVisible}>
                        <img id={id} onClick={() => handleOpen(index)} className={classes.img} src={thumb}
                             alt={'thumbPic'}/>
                    </LazyLoad>
                    {!isVisible && <Row middle={'xs'} center={'xs'} className={classes.loading}>
                        <LoadingComponent/>
                    </Row>}
                </div>
            </Fragment>

        )
    }
}

export default injectSheet(styles)(OwnGallery);