import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from "history";
import initalize from './config/store'
import {Provider} from "react-redux";
import {ParallaxProvider} from "react-scroll-parallax";
import {BreakpointProvider} from 'react-socks';
import ReactGA from 'react-ga4';

// ReactGA.initialize('UA-140602525-1');
//Analytics For Developement
/*ReactGA.initialize('UA-XXXXXXXXX-X', {
    'cookieDomain': 'auto',
    'debug': true
});*/


const rootEl = document.getElementById('root');
export const history = createBrowserHistory();
history.listen(location => location.hash ? ReactGA.pageview(location.hash.replace('#', '')) : ReactGA.pageview('home'));
export const store = initalize();

ReactDOM.render(
    <Provider store={store}>
        <ParallaxProvider>
            <BreakpointProvider>
                <App/>
            </BreakpointProvider>
        </ParallaxProvider>
    </Provider>
    , rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
