import {applyMiddleware, compose, createStore} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer from '../reducers';
import loggerMiddleware from './loggerMiddleware';
import errorMiddleware from './error-middleware';


const defaultMiddlewares = () => {
    let defaultResult = [
        errorMiddleware,
        thunkMiddleware,
        promiseMiddleware(),
    ];

    if (process.env.NODE_ENV === 'development') {
        defaultResult.push(loggerMiddleware);
    }

    return defaultResult;
};

const windowIfDefined = typeof window === 'undefined' ? null : window;

const composeEnhancers =
    typeof window === 'object' &&
    windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = (middlewares) => composeEnhancers(
    applyMiddleware(...defaultMiddlewares(), ...middlewares));


const composedMiddlewares = (middlewares) => process.env.NODE_ENV === 'development' ?
    enhancer(middlewares) :
    compose(applyMiddleware(...defaultMiddlewares(), ...middlewares));

const initialize = (initialState = {}, middlewares = []) => {
    return createStore(reducer, initialState, composedMiddlewares(middlewares));
};

export default initialize;
