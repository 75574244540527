import React, {Component} from 'react';
import injectSheet from 'react-jss'
import {Col, Row,} from "react-flexbox-grid";
import {ABOUT_US} from "../constants";
import background from '../assets/images/bg/clipped-stone-bg-about_v2.png'
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import {bgType, decideDeviceBackground} from "./utils/functionutils";

const styles = {
    root: {
        backgroundImage: () => decideDeviceBackground(bgType.LIGHT, background),
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        //backgroundAttachment: 'fixed',
        backgroundSize: 'cover'

    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '25px',
        }
    },
    contentContainer: {
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },

    },
    headline: {
        //color: '#5fb141',
        color: '#ffde1d',
        //textShadow: '6px 8px 14px rgba(0, 0, 0, .6)',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        //fontSize: props => `calc(${props.isRus ? 3 : 4}rem + (${props.isRus ? 60 : 80} - ${props.isRus ? 3 : 4}) * ((100vw - 300px) / (1920 - 300)))`,
        //fontWeight: '900',
        "@media (max-width:768px)": {
            paddingLeft: '40px',
            marginTop: '30px'
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
    },
    content: props => ({
        fontFamily: 'PFAgoraSlabProReg',
        fontweight: 'normal',
        fontSize: `${props.isRus ? 2.4 : 2.6}vh`
    })

};

class AboutUs extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#aboutus')
        }
    }

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        return (
            <div id={'aboutUs'} className={classes.root}>
                <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row>
                        <Col xs={11} className={classes.headlineContainer}>
                            <p className={classes.headline}>
                                {ABOUT_US.title[pageLanguage]}
                            </p>
                        </Col>
                    </Row>
                </Animated>
                <Animated animationIn="fadeInUpBig" animationInDelay={100} isVisible={isVisibleFromTop}>
                    <Row className={classes.contentContainer}>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                        <Col xs={10} lg={7} className={classes.contentContainer}>
                            <div className={classes.content}>
                                {ABOUT_US.content[pageLanguage]}
                            </div>
                        </Col>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    </Row>
                </Animated>
            </div>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(AboutUs)
