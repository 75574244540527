const navTitle = {
    home: {
        rus: 'ГЛАВНАЯ',
        eng: 'Home'
    },
    aboutUs: {
        rus: 'О КОМПАНИИ',
        eng: 'About us'
    },
    taxRebate: {
        rus: 'НАЛОГОВЫЕ ЛЬГОТЫ',
        eng: 'Tax Rebate'
    },
    whoWeAre: {
        rus: 'НАША КОМАНДА',
        eng: 'Our Team'
    },
    OurDirectors: {
        rus: 'НАШИ РЕЖИССЁРЫ',
        eng: 'Directors We Like'
    },
    Gallery: {
        rus: 'ГАЛЕРЕЯ',
        eng: 'Gallery'
    },
    OurWorks: {
        rus: 'ПРОЕКТЫ',
        eng: 'Works'
    },
    contactUs: {
        rus: 'КОНТАКТЫ',
        eng: 'Contact us'
    }
};


export const NAV_CONSTANT = (language = 'eng') => ([
    {
        title: navTitle.home[language],
        src: '/'
    }, {
        title: navTitle.aboutUs[language],
        src: 'aboutus'
    }, {
        title: navTitle.taxRebate[language],
        src: 'taxrebate'
    }, {
        title: navTitle.whoWeAre[language],
        src: 'whoweare'
    }, {
        title: navTitle.OurDirectors[language],
        src: 'ourdirectors'
    }, {
        title: navTitle.Gallery[language],
        src: 'gallery'
    },{
        title: navTitle.OurWorks[language],
        src: 'ourworks'
    }, {
        title: navTitle.contactUs[language],
        src: 'contactus'
    },
]);