import React, {Component, Fragment} from 'react';

class DetectViewport extends Component {

    state = {isVisible: false, isVisibleFromTop: false};

    componentDidMount() {
        if (this.props.elementId) {
            document.addEventListener('scroll', this.detectViewPort);
        }
    }

    detectViewPort = (e) => {
        const {elementId} = this.props;
        const el = document.getElementById(elementId);
        const isInView = this.isInViewport(el);
        const isInViewFromTop = this.isInViewportFromTop(el);

        this.setState(prevState => {
            if (prevState.isVisible !== isInView) {
                return {isVisible: isInView}
            }
            if (prevState.isVisibleFromTop !== isInViewFromTop) {
                return {isVisibleFromTop: isInViewFromTop}
            }
        });
    };

    isInViewport = (el) => {
        if (el) {
            const bounding = el.getBoundingClientRect();
            return (
                bounding.top -50 <= 0 &&
                bounding.height - 10 >= Math.abs(bounding.top || 0)

            )
        }

    };

    isInViewportFromTop = (el) => {
        if (el) {
            const bounding = el.getBoundingClientRect();
            return (
               /* bounding.top + 50 <= bounding.height &&
                bounding.height - 10 >= Math.abs(bounding.top || 0)*/
                bounding.y <= window.innerHeight - 50 &&
                Math.abs(bounding.y) <= bounding.height - 20

            )
        }

    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, {isVisible: this.state.isVisible, isVisibleFromTop: this.state.isVisibleFromTop})
        );
        return (
            <Fragment>
                {childrenWithProps}
            </Fragment>
        )
    }
}

export default DetectViewport
