import {combineReducers} from "redux";
import languageSelector from './languageselector'
import scroll from './scroll'
export const appReducer = combineReducers({
    languageSelector,
    scroll
});

export default (state, action) => {
    return appReducer(state, action);
}
