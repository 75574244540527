import {isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import mobileBgDark from "../../assets/images/bg/mobile/mobile_stone_bg.jpg";
import mobileBgLight from "../../assets/images/bg/mobile/mobile_stone_bg_light.jpg";
import tabletBgDark from "../../assets/images/bg/mobile/tablet_stone_bg.jpg";
import tabletBgLight from "../../assets/images/bg/mobile/mobile_stone_bg_light.jpg";


export const bgType = {LIGHT: 'light', DARK: 'dark'};

export const decideDeviceBackground = (type = '', bg) => {

    if (type === bgType.DARK && isMobile)
        return isMobileOnly ? `url(${mobileBgDark})` : isTablet ? `url(${tabletBgDark})` : '';
    else if (type === bgType.LIGHT && isMobile) {
        return isMobileOnly ? `url(${mobileBgLight})` : isTablet ? `url(${tabletBgLight})` : '';
    } else {
        return `url(${bg})`
    }
};

export const decideDeviceBottomShadow = (jssClass) => !isMobile ? jssClass : '';
export const decideDeviceTopShadow = (jssClass) => !isMobile ? jssClass : '';
export const hoverCardOnMobile = () => !isMobile ? {transform: 'scale(1.1)', filter: `blur(3px)`} : {};
export const parallaxScrollOnMobile = () => !isMobile ? { backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed'} : {};