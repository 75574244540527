import React, {Component, Fragment} from 'react';
import injectSheet from "react-jss";
import {Col, Row} from "react-flexbox-grid";
import background from "../assets/images/bg/clipped-stone-bg-gallery_v3.png";
import OwnGallery from "../compontents/owngallery";
import {GALLERY} from "../constants";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Animated} from "react-animated-css";
import Lightbox from "react-images";
import {isMobile} from 'react-device-detect';
import {bgType, decideDeviceBackground} from "./utils/functionutils";
import instagram from "../assets/images/social/social_instagram_black.png";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";


const styles = {
    root: () => {
        const rootStyleObj = {
            backgroundImage: decideDeviceBackground(bgType.LIGHT, background),
            minHeight: '100vh'
        };
        if (isMobile) {
            return {...rootStyleObj, backgroundRepeat: 'repeat-y', overflowX: 'hidden'}
        } else {
            return {...rootStyleObj, backgroundSize: 'cover'}
        }
    },
    headlineContainer: {
        marginTop: '75px',
        "@media (min-width:768px)": {
            marginLeft: '50px'
        },
        "@media (max-width:420px)": {
            marginTop: '60px',
        }
    },
    headline: {
        color: '#ffde1d',
        fontFamily: props => props.isRus ? 'BERNIERDistressed' : 'HVD Poster',
        fontSize: `calc(4rem + (80 - 4) * ((100vw - 300px) / (1920 - 300)))`,
        "@media (max-width:768px)": {
            paddingLeft: '40px',
        },
        margin: '-1.2vh',
        padding: '0px',
        cursor: 'default',
        pointerEvents: 'none',
        textTransform: props => props.isRus ? 'lowercase' : 'uppercase',
    },
    contentContainer: {
        marginTop: '25px',
        "@media (min-width:768px)": {
            marginLeft: '30px'
        },
    },
    content: {
        fontFamily: 'PFAgoraSlabProReg',
        fontweight: 'normal',
        fontSize: '2.6vh'
    },
    table: {
        "@media (max-width:450px)": {
            fontSize: '0.7rem'
        }
    },
    tableHead: {
        "@media (max-width:450px)": {
            fontSize: '0.5rem'
        },
        backgroundColor: 'rgba(0,0,0, .2)',
        fontWeight: 'bold',
        borderTop: '0px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '0px solid black'
    },
    largeMargin: {
        "@media (min-width:1450px)": {
            marginTop: '50px',
        }
    },
    tableCell: {
        "@media (max-width:450px)": {
            fontSize: '0.5rem'
        },
        borderTop: '0px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '0px solid black'
    },
    showMoreOnMobile: {
        zIndex: 9999,
        maxWidth: '100vw',
        background: '#ffde1d',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'inset 0px -22px 22px -21px rgba(0,0,0,0.75)'
    },
    showMore: {
        color: 'black',
        fontFamily: 'PFAgoraSlabProReg',
        fontSize: '1.8rem',
        fontWeight: '900',
        margin: '10px'
    },
    visitInsta: {
        margin: '0px',
        fontWeight: 'bold',
        "@media (min-width:768px)": {
            marginLeft: '75px'
        },
        "@media (max-width:768px)": {
            paddingLeft: '50px',
            marginTop: '5px !important',
            fontSize: '4.7vw !important'
        },
        fontSize: `calc(1.7rem + (1.7 - 0.09) * ((100vw - 300px) / (1920 - 300)))`,
        color: '#232323',
        cursor: 'pointer',
        transition: 'color .2s ease-in-out',
        '&:hover': {
            color: '#2395b1'
        },

    },
    instaImage: {
        marginLeft: '10px',
        cursor: 'pointer'
    },
    inlineFlex: {
        display: 'inline-flex',
        alignItems: 'center'
    }

};

class Gallery extends Component {

    state = {galleryMaxHeight: 0, isOpen: false, currentImage: 1, galleryMobileHeight: 0, isShowMoreClicked: false};

    componentDidMount() {
        const galleryTop = document.getElementById('galleryRow').offsetTop;
        const InnerHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        //const isLargScreen = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1360;

        if (!isMobile) {
            this.setState({galleryMaxHeight: (InnerHeight - galleryTop) - 20})
        } else {
            this.setState({galleryMobileHeight: `${InnerHeight}px`})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isVisible, history} = this.props;
        if (isVisible && (isVisible !== prevProps.isVisible)) {
            history.push('/#gallery')
        }
    }


    onClose = () => {
        this.setState({
            currentImage: 0,
            isOpen: false,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    handleOpen = (index) => {
        this.setState({isOpen: true, currentImage: index})
    };

    goToInstagram = () => window.open(GALLERY.instagramSrc, '_blank');

    galleryEl = () => {
        const {isVisibleFromTop} = this.props;
        const imgs = GALLERY.gallery.map((pic) => ({src: pic.fullRez}));
        return (
            <Fragment>
                {GALLERY.gallery.map((pic, index) => {
                    return (
                        <Col xs={6} md={4} lg={2} key={`gallery-${index}`}>
                            <Animated animationIn="flipInY" animationInDelay={600} isVisible={isVisibleFromTop}>
                                <OwnGallery
                                    handleOpen={this.handleOpen}
                                    index={index}
                                    thumb={pic.thumb}
                                    fullRez={pic.fullRez}
                                    id={`gallery-${index}`}
                                />
                            </Animated>
                        </Col>
                    )
                })}
                <Lightbox
                    images={imgs}
                    preventScroll={true}
                    currentImage={this.state.currentImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    isOpen={this.state.isOpen}
                    onClose={this.onClose}/>

            </Fragment>)

    };

    mobileStyleFn = mobileHeight => isMobile ? {
        overflowY: 'hidden',
        maxHeight: mobileHeight
    } : {};

    expandMobileGallery = () => this.setState({
            galleryMobileHeight: '100%',
            isShowMoreClicked: true
        }
    );

    render() {
        const {classes, pageLanguage, isVisibleFromTop} = this.props;
        const {galleryMaxHeight, galleryMobileHeight, isShowMoreClicked} = this.state;
        const scrollStyle = !isMobile ? {maxHeight: `${galleryMaxHeight}px`, overflowY: 'scroll'} : {};

        return (
            <Fragment>
                <div id={'gallery'} style={this.mobileStyleFn(galleryMobileHeight)} className={classes.root}>
                    <Animated animationIn="fadeInUp" animationInDelay={100} isVisible={isVisibleFromTop}>
                        <Row>
                            <Col xs={11} className={classes.headlineContainer}>
                                <p className={classes.headline}>{GALLERY.title[pageLanguage]}</p>
                            </Col>
                            <Col xs={11}>

                                <p className={classNames(classes.content, classes.visitInsta, classes.inlineFlex)}
                                   onClick={() => this.goToInstagram()}>
                                    {GALLERY.instagram[pageLanguage]}
                                    <span className={classes.inlineFlex}>
                                    <img
                                        onClick={() => this.goToInstagram()}
                                        className={classes.instaImage}
                                        height={isMobile ? 25 : 30}
                                        src={instagram}
                                        data-tip data-for='instagramlogo'
                                        alt={'instagram'}/>
                                </span>
                                </p>
                            </Col>
                        </Row>
                    </Animated>
                    <Row className={classes.contentContainer}>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                        <Col xs={10} lg={7} className={classes.contentContainer}>
                            <Row id={'galleryRow'} style={scrollStyle}>
                                {this.galleryEl()}
                            </Row>
                        </Col>
                        <Col xs={1} lg={0} className={'hideOnLarge'}/>
                    </Row>
                </div>
                {(isMobile && !isShowMoreClicked) &&
                <div className={classes.showMoreOnMobile} onClick={() => this.expandMobileGallery()}>
                    <p className={classes.showMore}>{GALLERY.showMore[pageLanguage]}</p>
                </div>}
                <ReactTooltip id='instagramlogo'>
                    <span>Instagram</span>
                </ReactTooltip>
            </Fragment>
        )
    }
}

export default compose(
    injectSheet(styles),
    withRouter
)(Gallery)
