import React, {Component, Fragment} from 'react';
import {isMobile, isMobileOnly, isTablet, isBrowser, isIE} from 'react-device-detect';

class DeviceDetectionWrapper extends Component{

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
                isMobile: isMobile,
                isMobileOnly: isMobileOnly,
                isTablet: isTablet,
                isBrowser: isBrowser,
                isIE: isIE
            })
        );
        return (
            <Fragment>
                {childrenWithProps}
            </Fragment>
        )
    }
}

export default DeviceDetectionWrapper;